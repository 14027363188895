import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import FloatInput from "./components/FloatInput"
import { Select as AntSelect, theme, Form as AntdForm } from "antd"
import styled from "styled-components"

const { useToken } = theme

const Select = styled(AntSelect)(({ token: { Select, colorDisabledBg, colorDisabledText }, disabled }) => ({
  font: "inherit",
  letterSpacing: "inherit",
  color: "currentcolor",
  border: "0px",
  boxSizing: "content-box",
  lineHeight: Select.lineHeight,
  margin: "0px",
  minWidth: "0px",
  width: "100%",
  animationDuration: "10ms",
  backgroundColor: "white",

  "&& .ant-select-selector": {
    backgroundColor: disabled ? `${colorDisabledBg} !important` : "white",
    padding: "1px 10px !important",
    borderColor: disabled ? `${colorDisabledBg} !important` : "white",
    color: disabled ? `${colorDisabledText} !important` : "",
    margin: "0px !important"
  },

  "&& .ant-select-selection-item": {
    fontSize: "14px"
  },
  ":focus": {
    boxShadow: "none",
    borderColor: "transparent"
  }
}))

const Form = styled(AntdForm.Item)({
  marginBottom: "16px !important"
})

export default function RHFSelect({ name, label, required, placeholder, size = "middle", ...other }) {
  const { control } = useFormContext()
  const { token } = useToken()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Form label={label} required={required} validateStatus={!!error && "error"} help={error?.message}>
          <FloatInput
            required={required}
            placeholder={placeholder}
            value={field.value}
            name={name}
            error={!!error}
            size={size}
            disabled={other.disabled}
          >
            <Select size={size} bordered={false} token={token} {...field} {...other} />
          </FloatInput>
        </Form>
      )}
    />
  )
}

RHFSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string
}
