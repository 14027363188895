import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import FloatInput from "./components/FloatInputDatePicker"
import { DatePicker as AntdDatePicker, TimePicker as AntdTimePicker, theme, Form as AntdForm, Row, Col } from "antd"
import styled from "styled-components"

const { useToken } = theme

const DatePicker = styled(AntdDatePicker)(({ token: { DatePicker } }) => ({
  font: "inherit",
  letterSpacing: "inherit",
  color: "currentcolor",
  border: "0px",
  boxSizing: "content-box",
  background: "none",
  lineHeight: DatePicker.lineHeight,
  margin: "0px",
  minWidth: "0px",
  width: "100%",
  animationDuration: "10ms",
  boxShadow: "none !important",
  backgroundColor: "#FFF",
  ":focus": {
    boxShadow: "none !important",
    borderColor: "transparent !important"
  }
}))

const TimePicker = styled(AntdTimePicker)(({ token: { TimePicker } }) => ({
  font: "inherit",
  letterSpacing: "inherit",
  color: "currentcolor",
  border: "0px",
  boxSizing: "content-box",
  background: "none",
  lineHeight: TimePicker.lineHeight,
  margin: "0px",
  minWidth: "0px",
  width: "100%",
  animationDuration: "10ms",
  backgroundColor: "#FFF",
  ":focus": {
    boxShadow: "none",
    borderColor: "transparent"
  }
}))

const Form = styled(AntdForm.Item)({
  marginBottom: "16px !important"
})

export default function RHFDateTimePickerRange({
  name,
  label,
  required,
  prefix,
  size = "middle",
  placeholder,
  datePickerStyle,
  timePickerStyle,
  ...other
}) {
  const { control } = useFormContext()
  const { token } = useToken()

  return (
    <Row type="flex">
      <Col span={12}>
        {/* date */}
        <Controller
          name={name?.[0]}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form label={label?.[0]} required={required?.[0]} validateStatus={!!error && "error"} help={error?.message}>
              <FloatInput
                required={required?.[0]}
                placeholder={placeholder?.[0]}
                value={field.value}
                name={name?.[0]}
                prefix={prefix?.[0]}
                error={!!error}
                size={size}
                order={1}
              >
                <DatePicker
                  showNow={false}
                  placeholder={null}
                  format="DD/MM/YYYY"
                  token={token}
                  {...field}
                  {...other}
                  style={{
                    ...datePickerStyle
                  }}
                />
              </FloatInput>
            </Form>
          )}
        />
      </Col>
      <Col span={12}>
        {/* time */}
        <Controller
          name={name?.[1]}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form label={label?.[1]} required={required?.[1]} validateStatus={!!error && "error"} help={error?.message}>
              <FloatInput
                required={required?.[1]}
                placeholder={placeholder?.[1]}
                value={field.value}
                name={name?.[1]}
                prefix={prefix?.[1]}
                error={!!error}
                size={size}
                order={2}
              >
                <TimePicker
                  popupClassName="time-picker-hide-confirm-button"
                  showNow={false}
                  format="HH:mm"
                  minuteStep={10}
                  placeholder={null}
                  token={token}
                  {...field}
                  {...other}
                  onSelect={(value) => {
                    field.onChange(value)
                  }}
                  style={{
                    ...timePickerStyle
                  }}
                />
              </FloatInput>
            </Form>
          )}
        />
      </Col>
    </Row>
  )
}

RHFDateTimePickerRange.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  prefix: PropTypes.node,
  placeholder: PropTypes.string
}
