import { useForm } from "react-hook-form"
import { Button, Typography, space } from "antd"
import { yupResolver } from "@hookform/resolvers/yup"
import styled from "styled-components"
import * as Yup from "yup"
import { RHFInputLogin, FormProvider } from "components/hook-form"
import useAuth from "hooks/useAuth"
import { MailOutlined, LockOutlined } from "@ant-design/icons"
import useLocales from "hooks/useLocales"

const { Title } = Typography

const LoginFormStyle = styled.section({
  // set auto responsive
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  maxWidth: "400px",
  margin: "0 auto"
})

export default function LoginForm() {
  const { login } = useAuth()
  const { translate } = useLocales()

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(`${translate("r.required")}`),
    password: Yup.string().required(`${translate("r.required")}`)
  })

  const defaultValues = {
    username: "",
    password: ""
  }

  const method = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  })

  const { handleSubmit, setError } = method

  const onSubmit = async (data) => {
    try {
      await login(data)
    } catch (error) {
      setError("username", { message: translate("u.username-or-password-invalid") })
      setError("password", { message: translate("u.username-or-password-invalid") })
    }
  }

  return (
    <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
      <LoginFormStyle>
        <Title level={2}>{translate("w.welcome-to")} CLOUDTIME PASSENGER</Title>
        <div style={{ marginTop: "32px" }}>
          <RHFInputLogin
            type="text"
            prefix={<MailOutlined />}
            size="large"
            placeholder={translate("u.username")}
            name="username"
          />
          <RHFInputLogin
            type="password"
            prefix={<LockOutlined />}
            size="large"
            placeholder={translate("p.password")}
            name="password"
          />

          <Button
            type="primary"
            size="large"
            block
            htmlType="submit"
            style={{ borderRadius: "14px", marginTop: "8px" }}
          >
            {translate("l.login")}
          </Button>
        </div>
      </LoginFormStyle>
    </FormProvider>
  )
}
