import { createElement } from "react"
import PropTypes from "prop-types"
import { Avatar, Layout, Space, Dropdown, Button } from "antd"
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  CaretDownOutlined
} from "@ant-design/icons"
import styled from "styled-components"
import useAuth from "hooks/useAuth"
import useLocales from "hooks/useLocales"

const { Header } = Layout

const Toolbar = styled.div({
  display: "flex",
  width: "100%",
  alignItems: "center",
  position: "relative",
  minHeight: "56px",
  height: "100%"
})

const Account = styled(Space)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  flexGrow: 1,
  cursor: "pointer"
})

const ButtonContainer = styled(Space)({})

export default function MainHeader({ collapsed, onCollapsed }) {
  const { user, logout } = useAuth()
  const { onChangeLang, translate, currentLang, allLang } = useLocales()

  // test testw
  const handleLogout = async (data) => {
    try {
      await logout(data)
    } catch (error) {
      console.log(error)
    }
  }
  const items = [
    {
      label: (
        <>
          <LogoutOutlined style={{ marginRight: "10px" }} />
          {translate("l.logout")}
        </>
      ),
      key: "1",
      onClick: handleLogout
    }
  ]

  const language = allLang.map((item) => {
    return {
      label: item.label,
      icon: item.icon,
      onClick: () => onChangeLang(item.value)
    }
  })

  const item = language

  return (
    <Header>
      <Toolbar>
        {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          onClick: onCollapsed
        })}
        <Account style={{ flexWrap: "wrap" }}>
          <Dropdown
            menu={{
              items: item
            }}
            trigger={["click"]}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className="language-avatar" icon={currentLang.icon} />
              <div className="header-lang">
                <span>
                  {`${currentLang.label}`}
                  <CaretDownOutlined />
                </span>
              </div>
            </div>
          </Dropdown>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className="account-user-avatar" icon={<UserOutlined />} />
              <div className="account-user">
                <span className="account-user-name">{`${user?.firstname} ${user?.lastname}`}</span>
              </div>
            </div>
          </Dropdown>
        </Account>
      </Toolbar>
    </Header>
  )
}

MainHeader.propTypes = {
  collapsed: PropTypes.bool,
  onCollapsed: PropTypes.func
}
