import useLocales from "hooks/useLocales"
import { ReactComponent as CardIcon } from "assets/icon/card.svg"
import { ReactComponent as StaffIcon } from "assets/icon/staff.svg"
import { ReactComponent as VehicleIcon } from "assets/icon/vehicle.svg"
import { ReactComponent as DriverIcon } from "assets/icon/driver.svg"
import { ReactComponent as DeviceIcon } from "assets/icon/device.svg"
import { ReactComponent as ReportIcon } from "assets/icon/report.svg"
import { ReactComponent as DashboardIcon } from "assets/icon/dashboard.svg"
import { ReactComponent as LocationIcon } from "assets/icon/location.svg"
import { ReactComponent as RouteIcon } from "assets/icon/route.svg"
import { ReactComponent as TripIcon } from "assets/icon/trip.svg"
import { ReactComponent as UserIcon } from "assets/icon/user.svg"
import { ReactComponent as UserPermissionIcon } from "assets/icon/user_permission.svg"
import { ReactComponent as GroupPermissionIcon } from "assets/icon/group_permission.svg"
import { ReactComponent as PermissionIcon } from "assets/icon/permission.svg"
import { ReactComponent as FirmwareIcon } from "assets/icon/firmware.svg"
import localeTH from "antd/locale/th_TH"
import localeEN from "antd/locale/en_US"
import Icon from "@ant-design/icons"
import THLogo from "assets/th.png"
import ENLogo from "assets/usa.png"
import styled from "styled-components"

// navbar
export const NAV_ITEM = [
  {
    key: "dashboard",
    title: "d.dashboard",
    type: "group",
    children: [
      {
        key: "main-dashboard",
        icon: <Icon component={DashboardIcon} />,
        title: "d.dashboard",
        url: "/main/dashboard"
      }
    ]
  },
  {
    key: "master-data",
    title: "m.master-data",
    type: "group",
    children: [
      {
        key: "card",
        icon: <Icon component={CardIcon} />,
        title: "c.card",
        url: "/main/card"
      },
      {
        key: "staff",
        icon: <Icon component={StaffIcon} />,
        title: "s.staff",
        url: "/main/staff"
      },
      {
        key: "vehicle",
        icon: <Icon component={VehicleIcon} />,
        title: "v.vehicle",
        url: "/main/vehicle"
      },
      {
        key: "driver",
        icon: <Icon component={DriverIcon} />,
        title: "d.driver",
        url: "/main/driver"
      },
      {
        key: "device",
        icon: <Icon component={DeviceIcon} />,
        title: "d.device",
        url: "/main/device"
      },
      {
        key: "firmware",
        icon: <Icon component={FirmwareIcon} />,
        title: "f.firmware",
        url: "/main/firmware"
      }
      // {
      //   key: "customer",
      //   icon: <CopyrightOutlined />,
      //   title: "customer",
      //   url: "/main/customer"
      // },
      // {
      //   key: "company",
      //   icon: <ProjectOutlined />,
      //   title: "company",
      //   url: "/main/company"
      // }
    ]
  },
  {
    key: "manage-travel",
    title: "r.route",
    type: "group",
    children: [
      {
        key: "location",
        icon: <Icon component={LocationIcon} />,
        title: "l.location",
        url: "/main/location"
      },
      {
        key: "route",
        icon: <Icon component={RouteIcon} />,
        title: "r.route",
        url: "/main/route"
      },
      {
        key: "trip-management",
        icon: <Icon component={TripIcon} />,
        title: "t.trip-management",
        url: "/main/trip-management"
      }
    ]
  },
  {
    key: "reports",
    title: "r.reports",
    type: "group",
    children: [
      {
        key: "log-report",
        icon: <Icon component={ReportIcon} />,
        title: "l.log-report",
        url: "/main/reports/log"
      }
    ]
  },

  {
    key: "management",
    title: "m.management",
    type: "group",
    children: [
      {
        key: "user",
        icon: <Icon component={UserPermissionIcon} />,
        title: "u.user",
        url: "/main/user"
      },
      {
        key: "group",
        icon: <Icon component={GroupPermissionIcon} />,
        title: "g.group",
        url: "/main/group"
      },

      {
        key: "permission",
        icon: <Icon component={PermissionIcon} />,
        title: "p.permission",
        url: "/main/permission"
      }
    ]
  }
]

// responsive
export const BREAKPOINTS = {
  xs: 360,
  sm: 568,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920
}

const getMedia = (breakpoint) => `(min-width: ${breakpoint}px)`

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl)
}

export const HOST_API = process.env.REACT_APP_LARAVEL_ENDPOINT || ""

const ImgContainer = styled.div({
  position: "flex-start"
})

export const LANGS = [
  {
    label: "ไทย",
    value: "th",
    systemValue: localeTH,
    icon: (
      <ImgContainer>
        <img src={THLogo} alt="th logo" className="language-icon" />
      </ImgContainer>
    )
  },
  {
    label: "English",
    value: "en",
    systemValue: localeEN,
    icon: (
      <ImgContainer>
        <img src={ENLogo} alt="en logo" className="language-icon" />
      </ImgContainer>
    )
  }
]
